<template>
  <div class="rule-card-list">
    <div class="rule" v-for="rule of rules" :key="rule.id">
      <h1>{{ rule.title }}</h1>
      <cite>{{ rule.source }}</cite>
      <vue-markdown :source="rule.text" />
      <div>
        <span
          v-for="tag of rule.tags"
          :key="tag"
          class="tags-input-badge tags-input-badge-pill tags-input-badge-selected-default"
        >
          {{ tag }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render";
import "@voerro/vue-tagsinput/dist/style.css"; // for re-using the "tag" style

export default {
  name: "RuleCards",
  components: {
    VueMarkdown,
  },
  props: {
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style>
.rule {
  margin: 1rem auto;
  margin-bottom: 1.6%;
  box-shadow: 0 3px 7px -1px rgba(#000, 0.1);
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
  background: #ffffff;
  padding: 1rem;
}

.rule h1 {
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}

.rule cite {
  font-size: 0.75rem;
}

.rule p {
  white-space: pre-wrap;
  position: relative;
  margin: 1rem 0 0;
  &:first-of-type {
    margin-top: 1.25rem;
    &:before {
      content: "";
      position: absolute;
      height: 5px;
      background: #5ad67d;
      width: 35px;
      top: -0.75rem;
      border-radius: 3px;
    }
  }
}

.rule div {
  margin: 1rem 0 0;
}
</style>
