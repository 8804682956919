<template>
  <div class="home">
    <div id="error-message" v-if="errorMessage !== null">
      <span>Error loading data: {{ errorMessage }}</span>
    </div>
    <tags-input
      v-if="inputIsVisible"
      ref="tagsinput"
      element-id="tags"
      v-model="selectedTags"
      placeholder="What rules are you looking for?"
      typeahead-style="dropdown"
      :existing-tags="tagData"
      :typeahead="true"
      :typeahead-max-results="20"
      :typeahead-activation-threshold="1"
      :typeahead-hide-discard="true"
      :only-existing-tags="true"
      @blur="onBlur"
    />
    <rules :rules="filteredRules" />
  </div>
</template>

<script>
import VoerroTagsInput from "@voerro/vue-tagsinput";
import "@voerro/vue-tagsinput/dist/style.css";
import RuleCards from "../components/RuleCards.vue";

export default {
  name: "Home",
  components: {
    "tags-input": VoerroTagsInput,
    rules: RuleCards,
  },
  data() {
    return {
      tagData: [],
      rulesData: [],
      selectedTags: [],
      inputIsVisible: false,
      errorMessage: null,
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.showInput();
  },
  methods: {
    showInput() {
      // Show the input component
      this.inputIsVisible = true;

      // Focus the component, but we have to wait
      // so that it will be showing first.
      this.$nextTick(() => {
        this.focusInput();
      });
    },
    focusInput() {
      this.$refs.tagsinput.$refs.taginput.focus();
    },
    onBlur() {
      this.$nextTick(() => {
        this.focusInput();
      });
    },
    loadData() {
      Promise.all([this.getTagData(), this.getRulesData()])
        .then((values) => {
          this.tagData = values[0];
          this.rulesData = values[1];
        })
        .catch((error) => {
          this.errorMessage = error;
          console.error("There was an error loading app data", error);
        });
    },
    async getTagData() {
      const response = await fetch("data/tags.json");
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        throw new Error(error);
      }
      return data;
    },
    async getRulesData() {
      const response = await fetch("data/rules.json");
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        throw new Error(error);
      }
      return data;
    },
  },
  computed: {
    filteredRules() {
      if (this.selectedTags && this.selectedTags.length > 0) {
        return this.rulesData.filter((rule) => {
          return this.selectedTags.every((tag) => rule.tags.includes(tag.key));
        });
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
#error-message {
}
</style>
